* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:visited {
    color: inherit;
  }
  
  button.link {
    border: none;
    background-color: inherit;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
  }
  
  button.link:hover {
    text-decoration: underline;
  }
  